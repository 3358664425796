exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-kasjb-jsx": () => import("./../../../src/pages/admin-kasjb.jsx" /* webpackChunkName: "component---src-pages-admin-kasjb-jsx" */),
  "component---src-pages-admin-postulaciones-jsx": () => import("./../../../src/pages/admin-postulaciones.jsx" /* webpackChunkName: "component---src-pages-admin-postulaciones-jsx" */),
  "component---src-pages-admin-vacantes-[id]-jsx": () => import("./../../../src/pages/admin-vacantes/[id].jsx" /* webpackChunkName: "component---src-pages-admin-vacantes-[id]-jsx" */),
  "component---src-pages-admin-vacantes-jsx": () => import("./../../../src/pages/admin-vacantes.jsx" /* webpackChunkName: "component---src-pages-admin-vacantes-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nosotros-jsx": () => import("./../../../src/pages/nosotros.jsx" /* webpackChunkName: "component---src-pages-nosotros-jsx" */),
  "component---src-pages-servicios-jsx": () => import("./../../../src/pages/servicios.jsx" /* webpackChunkName: "component---src-pages-servicios-jsx" */),
  "component---src-pages-vacantes-[id]-jsx": () => import("./../../../src/pages/vacantes/[id].jsx" /* webpackChunkName: "component---src-pages-vacantes-[id]-jsx" */),
  "component---src-pages-vacantes-jsx": () => import("./../../../src/pages/vacantes.jsx" /* webpackChunkName: "component---src-pages-vacantes-jsx" */),
  "component---src-pages-vacantes-slug-jsx": () => import("./../../../src/pages/vacantesSlug.jsx" /* webpackChunkName: "component---src-pages-vacantes-slug-jsx" */)
}

